import React, { useState } from "react";

import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Modal from "./Modal";

function ImageDisplay({ imageOpen, selectedImage, closeImageOpen }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const onClose = () => {
    closeImageOpen();
    setCurrentImageIndex(0);
  };

  return (
    <Modal isOpen={imageOpen} onClose={onClose}>
      <section className="flex flex-col items-center justify-center overflow-hidden">
        {selectedImage && selectedImage.images.length > 0 && (
          <>
            <div className="relative  flex items-center justify-center">
              <img
                src={selectedImage.images[currentImageIndex]}
                alt={`carousel-${currentImageIndex}`}
                className="max-h-screen  object-contain"
                style={{ width: '90%' }}
              />
              {selectedImage.images.length > 1 && (
                <>
                  <button
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-secondary text-[#ffffff] hover:bg-gray-200 hover:text-black p-2 rounded-full"
                    onClick={() =>
                      setCurrentImageIndex((prevIndex) =>
                        prevIndex === 0
                          ? selectedImage.images.length - 1
                          : prevIndex - 1
                      )
                    }
                  >
                    &lt;
                  </button>
                  <button
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-secondary text-[#ffffff] hover:bg-gray-200 hover:text-black p-2 rounded-full"
                    onClick={() =>
                      setCurrentImageIndex((prevIndex) =>
                        prevIndex === selectedImage.images.length - 1
                          ? 0
                          : prevIndex + 1
                      )
                    }
                  >
                    &gt;
                  </button>
                </>
              )}
            </div>
            <button
              className="mt-4 btn px-5 bg-white hover:bg-gray-200 border-0"
              onClick={onClose}
            >
              Close
            </button>
          </>
        )}
      </section>
    </Modal>
  );
}

export default ImageDisplay;
