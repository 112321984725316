import "react-loading-skeleton/dist/skeleton.css";

/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from "react";

import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import product1 from "../../assets/images/products_dummy.jpeg";

import Footer from "../../components/Footer";
import Header from "../../components/Header";

import useDocumentTitle from "../../utils/documentTitle";
import selectBg from "../../utils/page_helper";

import { getAllProducts } from "../../utils/dataProvider/firebase/fb_products";
import { setProducts } from "../../redux/slices/product.slice";

import Loading from "../../components/Loading";

function Products(props) {
  const location = useLocation();

  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfo,
  }));

  const productstore = useSelector((state) => state.product);
  const mainstore = useSelector((state) => state.main);
  const { success, loading, error } = useSelector((state) => state.firebase);

  const dispatch = useDispatch();

  const getProducts = async () => {
    const result = await Promise.resolve(getAllProducts());
    dispatch(setProducts(result));
  };

  useEffect(() => {
    if (productstore.products.length == 0) {
      getProducts();
    }
  }, []);

  useDocumentTitle("Products");

  if (loading || mainstore.settings == null)
    return (
      <>
        <Loading />
      </>
    );
  if (error) return <div>Error: Database Error</div>;

  return (
    <>
      <Header />

      <main>
        <section className="px-4 md:px-[72px] py-8 h-auto">
          <div className="flex flex-col gap-4 md:gap-6 text-left">
            <h2 className="text-[28px] md:text-[36px] text-secondary font-bold leading-[36px] md:leading-[48px] text-left">
              {mainstore.settings == null
                ? ""
                : mainstore.settings.products.heading}
            </h2>
            <p className="text-[16px] md:text-[22px] text-quartenary font-[400] leading-[24px] md:leading-[32px] text-left">
              {mainstore.settings == null
                ? ""
                : mainstore.settings.products.page_description}
            </p>
          </div>
        </section>

        <section>
          <div className="">
            {productstore.products.map((product, idx) => {
              return (
                <div
                  id={`product${idx + 1}`}
                  key={idx}
                  className="grid grid-cols-1 md:grid-cols-3"
                >
                  {idx % 2 === 0 && (
                    <div className="hidden md:col-span-1 md:block">
                      <figure>
                        <img
                          className="w-full h-full object-cover"
                          src={product.image != "" ? product.image : product1}
                          alt="Album"
                        />
                      </figure>
                    </div>
                  )}

                  <div className={`md:col-span-2 ${selectBg(idx)[0]}`}>
                    <div className="flex flex-col gap-4 md:gap-6 px-4 md:px-[60px] py-6 md:py-[50px] text-left">
                      <h2
                        className={`text-[24px] md:text-[32px] ${
                          selectBg(idx)[1]
                        } font-bold leading-[36px] md:leading-[48px] text-left`}
                      >
                        {product.title}
                      </h2>
                      <div className="lg:hidden md:hidden">
                        <figure className="scroll-container rounded-md h-[150px]">
                          <img
                            className="w-full h-full object-cover"
                            src={product.image != "" ? product.image : product1}
                            alt="Album"
                          />
                        </figure>
                      </div>
                      <ul
                        className={`list-disc text-[14px] md:text-[18px] ${
                          selectBg(idx)[2]
                        } font-[400] leading-[24px] md:leading-[32px] pl-4 md:pl-6`}
                      >
                        {product["services"].map((service, ids) => (
                          <li key={ids} className="mb-2">
                            {service}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {idx % 2 != 0 && (
                    <div className="hidden md:col-span-1 md:block">
                      <figure>
                        <img
                          className="w-full h-full object-cover"
                          src={product.image != "" ? product.image : product1}
                          alt="Album"
                        />
                      </figure>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
  product: state.product,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
