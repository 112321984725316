// src/features/dataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const firebaseSlice = createSlice({
  name: 'firebase',
  initialState: {
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    fetchDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDataSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
    },
    fetchDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } = firebaseSlice.actions;

export default firebaseSlice.reducer;
