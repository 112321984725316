import React from "react";

import { BrowserRouter, Route, Routes, Navigate} from "react-router-dom";



import Auth from "./pages/Auth";
import ForgotPass from "./pages/Auth/ForgotPass";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ResetPass from "./pages/Auth/ResetPass";

import NotFound from "./pages/Error";
import Mainpage from "./pages/Mainpage";
import Products from "./pages/Products";
import Services from "./pages/Services";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Career from "./pages/Career";
import CareerDetails from "./pages/Career/CareerDetails";
import AddCareer from "./pages/Career/AddCareer";

import ScrollToTop from "./utils/scrollToTop";
import {
  CheckAuth,
  CheckIsAdmin,
  CheckNoAuth,
  TokenHandler,
} from "./utils/wrappers/protectedRoute";

const reload = () => window.location.reload();


const Routers = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<TokenHandler />}>
          {/* Public Route */}
          <Route path="*" element={<NotFound />} />

          <Route path="/sitemap.xml" onEnter={reload} />

          <Route index element={<Mainpage />} />
          <Route path="products/*" element={<Products title="Products" />} />
          <Route path="services/*" element={<Services title="Services" />} />
          <Route path="about/*" element={<About title="About Us" />} />
          <Route path="contact/*" element={<Contact title="Contact Us" />} />

          <Route path="careers/*" element={<Career title="Career" />} />
          <Route path="careers/details/:jobId" element={<CareerDetails />} />
          <Route path=".well-known/acme-challenge/tFZyap9GZVBrQlqoa5NmAlY3MLDiy7de3KrhTCYuTwfQzMuV4PE5XBc_EvFjnqR_" element={<Navigate to="./tFZyap9GZVBrQlqoa5NmAlY3MLDiy7de3KrhTCYuTwfQzMuV4PE5XBc_EvFjnqR_" />} />

          {/*         
          
        <Route path="menu/*" element={<Products title="Menu" />}>
            <Route path="category/:slug" element={""} />
           
        </Route> */}

          {/* Route which must not logged in */}
          <Route
            path="auth"
            element={
              <CheckNoAuth>
                <Auth />
              </CheckNoAuth>
            }
          >
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
          </Route>

          {/* Route which must logged in */}
          <Route element={<CheckAuth />}>
            {/* <Route path="profile" element={<Profile title="User Profile" />} /> */}
            {/* <Route path="history" element={<History />} />
            <Route path="history/:id" element={<HistoryDetail />} /> */}
          </Route>

          {/* Route which only admin */}
          <Route element={<CheckIsAdmin />}>
            <Route path="careers/add" element={<AddCareer />} />

            {/* <Route path="billing" element={<Cart />} />
          <Route path="reports" element={<AdminDashboard />} />
          
          
            <Route path="menu/new" element={<NewProduct />} />
            <Route path="manage-order" element={<ManageOrder />} />
            <Route path="menu/edit/:productId" element={<EditProduct />} />
            <Route path="promo/new" element={<NewPromo />} />
            <Route path="promo/edit/:promoId" element={<EditPromo />} /> */}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
