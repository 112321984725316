import "react-loading-skeleton/dist/skeleton.css";

/* eslint-disable react/prop-types */
import React from "react";
import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import useDocumentTitle from "../../utils/documentTitle";

import { checkDateExpiry } from "../../utils/helpers";

function Career(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfo,
  }));

  const mainstore = useSelector((state) => state.main);
  const careerstore = useSelector((state) => state.career);
  

  useDocumentTitle("Careers");

  return (
    <>
      <Header />

      <main>
        <div className="bg-[#F4F7FA]">
          <section className="px-4 md:px-[72px] py-4 md:py-8 h-auto">
            <div className="flex flex-col gap-4 md:gap-6 text-left">
              <h2 className="text-[24px] md:text-[36px] text-secondary font-bold leading-[32px] md:leading-[48px] text-left">
                {/* {mainstore.settings == null
                ? ""
                : mainstore.settings.services.heading} */}
                Career
              </h2>
              <p className="text-[16px] md:text-[22px] text-quartenary font-[400] leading-[24px] md:leading-[32px] text-left">
                {/* {mainstore.settings == null
                ? ""
                : mainstore.settings.services.page_description} */}
                A leading provider of cutting-edge electrical solutions.
              </p>
              {Number(props.userInfo.role) >= 2 && (
                <div className="card-actions justify-end">
                  <button
                    onClick={() => navigate("/careers/add")}
                    className="btn bg-[#2D7599] text-[#ffffff]"
                  >
                    Create Job
                  </button>
                </div>
              )}
            </div>
          </section>

          <section className="px-4 md:px-[72px] py-4 md:py-8">
            {careerstore.jobs.map((job, idx) => {
              return (
                <div key={idx} className="card bg-base-100 mb-4  shadow-xl">
                  <div className="card-body">
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                      <div className=" card-actions justify-start">
                        <h2 className="card-title text-[#222222]">
                          {job.job_title}
                        </h2>
                      </div>
                      <div className="hidden md:block lg:block"></div>
                      <div className="card-actions justify-end">
                        <span className="bg-[#473BF0] bg-opacity-10 text-[#473BF0] font-semibold md:font-semibold lg:font-semibold px-2 py-1 rounded-full">
                          {/* {job.job_added_date} */}

                          {job.job_added_date}
                        </span>
                      </div>
                    </div>
                    <div className="lg:w-1/2 my-4">
                      <p>{job.job_summary}</p>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
                      <div className="flex items-center card-actions justify-start">
                        <span className="flex items-center justify-center bg-[#2D7599] bg-opacity-10 text-[#2D7599] font-semibold w-32 px-2 py-1 rounded-full">
                          {job.job_type}
                        </span>

                        <span className=" flex items-center justify-center bg-[#822D81] bg-opacity-10 text-[#822D81] font-semibold w-32 px-2 py-1 rounded-full">
                          {checkDateExpiry(job.job_expiry_date >= 0)
                            ? "open"
                            : "closed"}
                        </span>
                      </div>
                      {checkDateExpiry(job.job_expiry_date >= 0) && Number(props.userInfo.role) <= 2 ? (
                        <div className="card-actions flex items-center justify-end">
                          <button
                            onClick={() =>
                              navigate(`/careers/details/${job.id}`)
                            }
                            className="btn btn-secondary text-[#ffffff]"
                          >
                            Apply for this job
                          </button>
                        </div>
                      ) : (
                        ""
                      )}

                      {checkDateExpiry(job.job_expiry_date == -1) &&
                      Number(props.userInfo.role) >= 2 ? (
                        <div className="card-actions flex items-center justify-end">
                          <button
                            onClick={() =>
                              navigate(`/careers/details/${job.id}`)
                            }
                            className="btn btn-secondary text-[#ffffff]"
                          >
                            View Job
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Career);
