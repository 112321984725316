import "react-loading-skeleton/dist/skeleton.css";

/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import job from "../../assets/images/linsta_services_careers.jpeg";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import useDocumentTitle from "../../utils/documentTitle";
import { toast } from "react-hot-toast";
import {
  getMessages,
  sendMessage,
} from "../../utils/dataProvider/firebase/fb_contact";
import { isValidEmail } from "../../utils/helpers";
import DeleteMessage from "../../components/DeleteMessage";

function Contact(props) {
  // const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState("");

  const [messages, setMessages] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);

  const [messageId, setMessageId] = useState(null);

  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfo,
  }));

  const [form, setForm] = useState({
    full_name: "",
    email: "",
    phone: "",
    subject: "Contact ",
    message: "",
  });

  const formChangeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      form.full_name === "" ||
      form.email === "" ||
      form.phone === "" ||
      form.subject === ""
    ) {
      return toast.error("Input required form");
    }

    if (!isValidEmail(form.email)) {
      return toast.error("Input valid email id");
    }

    if (form.phone.length < 10) {
      return toast.error("Input valid mobile number");
    }

    setLoading(true);
    sendMessage(form)
      .then((result) => {
        navigate(`/`);
        toast.success("Your message submitted.");
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (Number(props.userInfo.role) >= 2) {
      setLoading(true);
      getMessages()
        .then((result) => {
          setMessages(result);
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const deleteMessage = (id) => {
    setMessageId(id);
    setDeleteModal(true);
  };

  const closeModelOpen = () => {
    setMessageId(null);
    setDeleteModal(false);
  };

  const mainstore = useSelector((state) => state.main);
  const dispatch = useDispatch();

  useDocumentTitle("Contact");
  return (
    <>
      <DeleteMessage
        isOpen={deleteModal}
        onClose={() => closeModelOpen()}
        messageId={messageId}
      />
      <Header />

      <main>
        <div className="bg-[#F4F7FA]">
          <section className="px-4 lg:pl-[72px] py-8 h-auto">
            <div className="grid grid-cols-1 lg:grid-cols-5">
              {/* Image Section */}
              <div className="lg:col-span-4 hidden lg:block">
                <figure>
                  <img
                    className="object-cover w-[903px] h-[669px]"
                    src={job}
                    alt="Album"
                  />
                </figure>
              </div>

              {/* Text Section */}
              <div className="lg:col-span-1 bg-[#2947A9] lg:bg-[#2947A9] lg:w-[488px] lg:h-[523px] lg:relative lg:left-[-355px] lg:top-[75px]">
                <div className="text-[#ffffff] lg:px-[50px] lg:py-[50px] text-left px-4 py-6">
                  <h2 className="text-[24px] lg:text-[36px] font-[700] lg:leading-[48px] leading-[30px]">
                    Contact us
                  </h2>
                  <p className="heading-text py-3 text-[16px] lg:text-[20px] font-[200] lg:leading-[26px] leading-[20px]">
                    Welcome to our state-of-the-art panel board manufacturing
                    unit and factory. We pride ourselves on precision
                    engineering and cutting-edge technology to produce
                    high-quality panel boards for diverse applications. Explore
                    our facilitys commitment to innovation, quality control, and
                    sustainable practices.
                  </p>
                  <div className="justify-start">
                    <button className="btn w-full lg:w-[270px] bg-[#ffffff] text-[#2947A9]">
                      Contact Us
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="px-3 lg:px-[72px] py-8 h-auto">
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-5">
              <div className="flex items-left">
                <button className="w-[43px] h-[43px] btn btn-circle bg-[#ffffff] border-none">
                  <span className="text-[#473BF0] text-[17px] font-[400]">
                    1
                  </span>
                </button>
                <div className="px-5 text-left">
                  <h2 className="text-[21px] font-[700] leading-[48px]">
                    Call us
                  </h2>
                  {mainstore.settings.contacts &&
                    mainstore.settings.contacts["phone"].map((ph, idp) => {
                      return (
                        <p
                          key={idp}
                          className="heading-text text-[17px] font-[200] leading-[26px]"
                        >
                          {ph}
                        </p>
                      );
                    })}
                </div>
              </div>

              <div className="flex items-left">
                <button className="w-[43px] h-[43px] btn btn-circle bg-[#ffffff] border-none ">
                  <span className="text-[#473BF0] text-[17px] font-[400]">
                    2
                  </span>
                </button>
                <div className="px-5 text-left">
                  <h2 className="text-[21px]  font-[700] leading-[48px]">
                    Email us
                  </h2>
                  {mainstore.settings.contacts &&
                    mainstore.settings.contacts["email"].map((ph, idp) => {
                      return (
                        <p
                          key={idp}
                          className="heading-text text-[17px] font-[200] leading-[26px]"
                        >
                          {ph}
                        </p>
                      );
                    })}
                </div>
              </div>

              <div className="flex items-left">
                <button className="w-[43px] h-[43px] btn btn-circle bg-[#ffffff] border-none ">
                  <span className="text-[#473BF0] text-[17px] font-[400]">
                    3
                  </span>
                </button>
                <div className="px-5 text-left">
                  <h2 className="text-[21px]  font-[700] leading-[48px]">
                    Visit
                  </h2>
                  {mainstore.settings.contacts &&
                    mainstore.settings.contacts["locations"].map((ph, idp) => {
                      return (
                        <p
                          key={idp}
                          className="heading-text text-[17px] font-[200] leading-[26px]"
                        >
                          {ph}
                        </p>
                      );
                    })}
                </div>
              </div>
            </div>
          </section>

          <section className="global-px my-10">
            <div className="overflow-hidden rounded-lg relative h-0 pb-[58%] mb-2">
              <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1724.481895921659!2d76.65624968026506!3d9.93590928021987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOcKwNTYnMDkuNyJOIDc2wrAzOScyMi4zIkU!5e1!3m2!1sen!2sin!4v1723575123301!5m2!1sen!2sin"
                width="800"
                height="600"
                className="top-0 left-0 border-0 h-full w-full absolute"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>

              {/* Visible on larger screens */}
              <div className="hidden md:block card rounded-md bg-base-100 shadow-xl absolute z-10 bottom-5 right-14 w-60">
                <div className="card-body items-center text-center text-[16px] px-1 py-2 gap-0">
                  
                  <h2 className="card-title text-[16px]">Linsta Services</h2>
                  <p className="text-[16px] pb-3">MP Arcs, Vengachuvadu,</p>
                  
                  
                  <button className="btn w-full bg-secondary text-white"
                  onClick={() => window.location.href ='https://maps.app.goo.gl/1jFZqxN1CYHL2QVi8'}>
                    Locate Us
                  </button>
                </div>
              </div>
            </div>

            {/* Visible on smaller screens */}
            <div className="block md:hidden card bg-base-100 shadow-xl w-full mt-4">
              <div className="card-body items-center text-center text-[16px] px-4 py-4">
                <h2 className="card-title text-[16px]">Linsta Services</h2>
                <p className="text-[16px]">MP Arcs, Vengachuvadu,</p>
                <button className="btn btn-primary w-full bg-secondary text-white"
                onClick={() => window.location.href ='https://maps.app.goo.gl/1jFZqxN1CYHL2QVi8'}>
                  Locate Us
                </button>
              </div>
            </div>
          </section>

          <section id="contactForm" className="global-px my-10">
            {messages == null ? (
              <form
                onSubmit={submitHandler}
                className="card bg-base-100 shadow-xl"
              >
                <div className="card-body">
                  <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 lg:gap-5 gap-3 px-4 md:px-8 lg:mb-4 mb-4">
                    <div className="">
                      <p>Full Name</p>
                      <input
                        type="text"
                        placeholder="i.e. John Doe"
                        name="full_name"
                        id="full_name"
                        value={form.full_name}
                        onChange={formChangeHandler}
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="">
                      <p>Email</p>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        value={form.email}
                        onChange={formChangeHandler}
                        placeholder="i.e. john@mail.com"
                        className="input input-bordered w-full "
                      />
                    </div>
                    <div className="">
                      <p>Phone Number</p>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        value={form.phone}
                        onChange={formChangeHandler}
                        placeholder="i.e. +1-234-567-7890"
                        className="input input-bordered w-full "
                      />
                    </div>
                    <div className="">
                      <p>Subject</p>
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        value={form.subject}
                        onChange={formChangeHandler}
                        placeholder="i.e. I need a help"
                        className="input input-bordered w-full "
                      />
                    </div>
                    <div className="lg:col-span-2">
                      <p>Message</p>
                      <textarea
                        name="message"
                        id="message"
                        value={form.message}
                        onChange={formChangeHandler}
                        className="textarea textarea-bordered w-full"
                        placeholder="Type your message"
                      ></textarea>
                    </div>
                  </div>

                  <div className="card-actions justify-start px-4 md:px-8">
                    <button
                      type="submit"
                      onClick={submitHandler}
                      className={`${
                        isLoading && "loading"
                      } btn bg-secondary text-[#ffffff] w-52`}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              messages.length > 0 && (
                <div className="overflow-x-auto py-3">
                  <table className="table table-xs w-full">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Subject</th>
                        <th>Message</th>
                        <th>Message date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {messages &&
                        messages.map((msg, idm) => {
                          return (
                            <tr key={idm}>
                              <th>{idm + 1}</th>
                              <td>{msg.full_name}</td>
                              <td>
                                <div>
                                  <p className="text-bold">{msg.email}</p>
                                  <p>{msg.phone}</p>
                                </div>
                              </td>
                              <td>{msg.subject}</td>
                              <td>
                                <div className="whitespace-normal break-words">
                                  {msg.message}
                                </div>
                              </td>
                              <td>{msg.msg_send_date}</td>
                              <td>
                                <button
                                  onClick={() => deleteMessage(msg._id)}
                                  className="bg-[#473BF0] bg-opacity-10 text-[#473BF0] font-semibold md:font-semibold lg:font-semibold px-2 py-1 rounded-full"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )
            )}
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
