// src/App.js
import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FirebaseContext } from "./firebaseListener";
import Router from "./router";
import { Notification } from "./components/Notification";

import Loading from "./components/Loading"

function App() {
  const firebase = useContext(FirebaseContext);

  const { success, loading, error } = useSelector((state) => state.firebase);

  useEffect(() => {
    firebase.initialFetch();
  }, []);

  if (loading)
    return (
      <>
      <Loading/>
      </>
    );
  if (error) return <div>Error: Database Error</div>;

  return (
    <>
      <Router />
      <Notification />
    </>
  );
}

export default App;
