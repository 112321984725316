import "react-loading-skeleton/dist/skeleton.css";

/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { toast } from "react-hot-toast";
import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
} from "react-router-dom";

import Quill from "quill";
import "quill/dist/quill.snow.css"; // Import the styles for Quill
import Datepicker from "react-tailwindcss-datepicker";

import { formatDateTime, validateToken } from "../../utils/helpers";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import useDocumentTitle from "../../utils/documentTitle";

import { createNewJob } from "../../utils/dataProvider/firebase/fb_career";



function AddCareer(props) {
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [isLoading, setLoading] = useState("");

  const [form, setForm] = useState({
    job_type: "Fresher",
    job_title: "",
    job_description: "",
    job_summary: "",
    job_expiry_date: "", // real form
    startDate: null,
    endDate: null,
  });

  const formChangeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleValueChange = (newValue) => {
    setForm({
      ...form,
      job_expiry_date: newValue.startDate,
      startDate: newValue.startDate,
      endDate: newValue.startDate,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(form);

    if (
      form.job_type === "" ||
      form.job_title === "" ||
      form.job_description === "" ||
      form.job_summary === "" ||
      form.job_expiry_date === ""
    ) {
      return toast.error("Input required form");
    }

    const token_state = validateToken(props.userInfo.token);

    if (token_state.status == "failed") {
      return toast.error("You do not have permission to add Job.");
    }

    setLoading(true);
    createNewJob(form, props.userInfo.token)
      .then((result) => {
        console.log(result);
        navigate(`/careers/`);
        // httpTrigger();
        toast.success("Job added successfully");
      })
      .catch((err) => {
        if (err.response?.data?.msg) {
          toast.error(err.response?.data?.msg);
          return;
        }
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
  };

  const editorRef = useRef(null);
  const quillInstanceRef = useRef(null);

  useEffect(() => {
    if (editorRef.current && !quillInstanceRef.current) {
      const toolbarOptions = [
        [{ header: [1, 2, 3, false] }], // Headers
        ["bold", "italic", "underline", "strike"], // Text Formatting
        [{ script: "sub" }, { script: "super" }], // Subscript/Superscript
        [{ list: "ordered" }, { list: "bullet" }], // Lists
        [{ indent: "-1" }, { indent: "+1" }], // Indent/Outdent
        [{ direction: "rtl" }], // Text Direction
        [{ size: ["small", false, "large", "huge"] }], // Font Sizes
        [{ color: [] }, { background: [] }], // Text/Background Color
        [{ align: [] }], // Text Alignments
        ["link", "image", "video"], // Links, Images, Videos
        ["clean"], // Clear formatting
      ];

      const quill = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: toolbarOptions, // Use your custom toolbar
        },
      });

      quill.on("text-change", () => {
        const html = quill.getSemanticHTML();
        setContent(html);
        setForm((prevForm) => ({
          ...prevForm,
          job_description: html,
        }));
      });

      quillInstanceRef.current = quill;
    }
  }, []);

 

  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfo,
  }));

  const mainstore = useSelector((state) => state.main);
  const dispatch = useDispatch();

  useDocumentTitle("Careers");

  return (
    <>
      <Header />

      <main>
        <div className="bg-[#F4F7FA]">
          <section className="px-4 md:px-[72px] py-4 md:py-8 h-auto">
            <div className="flex flex-col gap-4 md:gap-6 text-left">
              <h2 className="text-[24px] md:text-[36px] text-quartenary font-bold leading-[32px] md:leading-[48px] text-left">
                Add Career
              </h2>
            </div>
          </section>

          <section className="px-4 md:px-[72px] py-4 md:py-8">
            <form
              onSubmit={submitHandler}
              className="card bg-base-100 shadow-xl"
            >
              <div className="card-body">
                <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 lg:gap-5 gap-3 px-4 md:px-8 lg:mb-4 mb-4">
                  <div className="">
                    <p className="mb-2">Job Title</p>
                    <input
                      type="text"
                      placeholder="i.e Intern"
                      name="job_title"
                      id="job_title"
                      value={form.job_title}
                      onChange={formChangeHandler}
                      className="input input-bordered w-full"
                    />
                  </div>
                  <div className="">
                    <p className="mb-2">Job Type</p>
                    <select
                      name="job_type"
                      id="job_type"
                      value={form.job_type}
                      onChange={formChangeHandler}
                      className="select select-bordered w-full max-w-xs"
                    >
                      <option>Fresher</option>
                      <option>Experienced</option>
                    </select>
                  </div>
                  <div className="">
                    <p className="mb-2">Job Summary</p>

                    <textarea
                      className="textarea textarea-bordered w-full"
                      name="job_summary"
                      id="job_summary"
                      onChange={formChangeHandler}
                      placeholder=""
                    >
                      {form.job_summary}
                    </textarea>
                  </div>
                  <div className="">
                    <p className="mb-2">Job Expiry Date</p>
                    <Datepicker
                      // containerClassName={"bg-white"}
                      inputClassName={
                        "bg-white border-b-2 py-2 border-gray-300 focus:border-tertiary outline-none w-full"
                      }
                      asSingle={true}
                      value={form}
                      popoverDirection="down"
                      onChange={handleValueChange}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 lg:gap-5 gap-3 px-4 md:px-8 lg:mb-4 mb-4">
                  <div>
                    <p className="mb-2">Job Description below:</p>
                    <div ref={editorRef} style={{ height: "300px" }} />
                  </div>

                  <div className="">
                    <p className="mb-2">Preview:</p>
                    <div className="createdList" dangerouslySetInnerHTML={{ __html: content }} />
                  </div>
                  <div className="flex items-center justify-start">
                    <button
                      type="submit"
                      className={`${
                        isLoading && "loading"
                      } btn normal-case mt-2 btn-secondary text-white shadow-lg rounded-2xl`}
                    >
                      Create Job
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddCareer);
