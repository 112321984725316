import _ from "lodash";
import jwtDecode from "jwt-decode";

export const n_f = (number) => {
  if (!number || isNaN(number)) return 0;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const inr_f = (number) => {
  if (!number || isNaN(number)) return 0;
  return "₹ " +  number.toString();
};

export const timespan_date = (timespan) => {
  const order_date = new Date(timespan.seconds * 1000 + timespan.nanoseconds / 1000000);
  return order_date.toString()
};

export const short_n_f = (number) => {
  if (isNaN(number)) {
    throw new Error("Input isn't number");
  }

  const isNegative = number < 0;
  const absoluteNumber = Math.abs(number);

  const abbreviations = ["", "K", "M", "B", "T", "Q"];
  const base = 1000;

  if (absoluteNumber < base) {
    return isNegative ? `-${absoluteNumber}` : absoluteNumber.toString();
  }

  const exponent = Math.floor(Math.log10(absoluteNumber) / Math.log10(base));
  const abbreviatedNumber = (absoluteNumber / Math.pow(base, exponent)).toFixed(
    2
  );

  return (isNegative ? "-" : "") + abbreviatedNumber + abbreviations[exponent];
};

export function formatDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);

  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const day = String(dateTime.getDate()).padStart(2, "0");

  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export const getEmailUsername = (email) => {
  if (!email || email === "") return "Anon";
  const username = _.head(_.split(email, "@"));
  return username;
};



export const validateToken = (token) =>{
  if(token.length > 0 ){

  
  try{
  const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decoded.exp < currentTime) {
        return {status:"failed",email:""}
      }

      return {status:"ok",email:decoded.email}
    }
    catch{
      return {status:"failed",email:""}
    }

  }
  else{
    return {status:"failed",email:""}
  }


}

export const getTimeStampDate = (date) =>{
    const date_str = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
    const yyyy = date_str.getFullYear();
    const mm = String(date_str.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JavaScript
    const dd = String(date_str.getDate()).padStart(2, '0');
    const final_date_str = `${yyyy}-${mm}-${dd}`
    return final_date_str
}

export const getDate = () =>{
  const date_str = new Date();
  const yyyy = date_str.getFullYear();
  const mm = String(date_str.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JavaScript
  const dd = String(date_str.getDate()).padStart(2, '0');
  const final_date_str = `${yyyy}-${mm}-${dd}`
  return final_date_str
}

export const checkDateExpiry = (date) =>{
  const d1 = new Date(date);
  const d2 = new Date(getDate());

  // Compare the two dates
  if (d1 < d2) {
      return -1
  } else if (d1 > d2) {
      return 1
  } else {
      return 0
  }
}

export const isValidEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

