import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: [],
    
  };

  const productSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        setProducts: (prevState, action) => {
            return {
              ...prevState,
              products: action.payload,
            };
          },
    },

})

export const {setProducts} = productSlice.actions;
export default productSlice.reducer;