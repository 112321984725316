 const selectBg = (idx) => {
    let colors = ['bg-[#2D7599]','bg-blacky','bg-violety']
    var selectedBg = ['bg-[#F4F7FA]','text-[#161C2D]','text-[#1A1A1A]']
    if (idx % 2 != 0){
      let check = Math.floor((idx/2) %3)
      if(check < colors.length) {
        selectedBg = [colors[check],'text-[#ffffff]','text-[#ffffff]']
       
      }
      
    }
      return selectedBg
  };

  export default selectBg