import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  query,
  where,
  doc,
  addDoc,
  updateDoc,
  orderBy,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

import { Timestamp } from "firebase/firestore";

import { getFbDatabase, getFbStorage } from "../../firebaseConn";
import { getTimeStampDate, validateToken } from "../../helpers";

const db = getFbDatabase();
const storage = getFbStorage();

export async function createDocument(col_name, form) {
  const docRef = await addDoc(collection(db, col_name), form);
  return docRef;
}

async function fileUpload(folder = "temp", img, image_name) {
  var final_url = undefined;
  if (img.name) {
    const extension = img.name.split(".").pop();

    const filename = image_name + "." + extension;

    const fileRef = storageRef(storage, folder + filename);

    await uploadBytes(fileRef, img);

    const url = getDownloadURL(fileRef);
    final_url = url;
  }

  return final_url;
}

export const deleteJobApplication = async (applicantId, token) => {
  if(token.length > 0){
    const final_out = {}

    const docRef = doc(db, "job_applications", applicantId);
    try {
  
      const docSnap = await deleteDoc(docRef);
      
        
        final_out['msg'] = "Successfully deleted job application."
  
        return final_out
     
    } catch (error) {
      final_out['msg'] = "Failed to delete job application."
      console.error("Error getting document:", error);
    }
  }
  
};

export async function applyCandidateJob(
  { full_name, email, phone, subject, message, attachment, job_id }
) {
  
  const bodyForm = {};
  bodyForm.full_name = full_name;
  bodyForm.email = email;
  bodyForm.phone = phone;
  bodyForm.job_apply_date = Timestamp.fromDate(new Date());
  bodyForm.subject = subject;
  bodyForm.message = message;
  bodyForm.job_id = job_id;
  bodyForm.mail_status = "added";

  const final_out = {};

  
    try {
      if (attachment && attachment.name != "") {
        await fileUpload(
          "job_attachments/",
          attachment,
          email + "_" + job_id
        ).then((url) => {
          const final_url = url;

          if (final_url) {
            bodyForm.attachment = final_url;
          }
        });
      }

      const docRef = createDocument("job_applications", bodyForm);
      final_out["msg"] = "Item added successfully.";
    } catch (error) {
      final_out["msg"] = error.message;
    }
  
  return final_out;
}

export async function createNewJob(
  { job_type, job_title, job_summary, job_description, job_expiry_date },
  token
) {
  const token_state = validateToken(token);
  const bodyForm = {};
  bodyForm.job_id = "LSJ" + String(Math.floor(Date.now() / 1000));
  bodyForm.job_type = job_type;
  bodyForm.job_title = job_title;
  bodyForm.job_summary = job_summary;
  bodyForm.job_added_date = Timestamp.fromDate(new Date());
  bodyForm.job_expiry_date = Timestamp.fromDate(new Date(job_expiry_date));
  bodyForm.job_description = job_description;

  const final_out = {};

  if (token.length > 0 && token_state.status == "ok") {
    try {
      const docRef = createDocument("jobs", bodyForm);
      final_out["msg"] = "Job added successfully.";
    } catch (error) {
      final_out["msg"] = error.message;
    }
  } else {
    final_out["msg"] = "User has no access to add new job.";
  }
  return final_out;
}

export async function getJobCandidates() {
  const candidatesList = Array();

  const candidatesRef = collection(db, "job_applications");
  const candidatesSnapshot = await getDocs(candidatesRef );

  candidatesSnapshot.forEach((doc) => {
    const data = doc.data();
    data["_id"] = doc.id;
    data["job_apply_date"] = getTimeStampDate(data.job_apply_date);
    candidatesList.push(data);
  });


  return candidatesList;
}

export async function getAllJobs() {
  const testimonyList = Array();

  const testimonyRef = collection(db, "testimonies");
  const testimonySnapshot = await getDocs(testimonyRef);

  testimonySnapshot.forEach((doc) => {
    const data = doc.data();
    data["_id"] = doc.id;
    testimonyList.push(data);
  });

  let result_list = Array();

  testimonyList.map((data) => {
    const obj = {};
    obj["_id"] = data["_id"];
    obj["content"] = data["content"];
    obj["detail"] = data["detail"];
    obj["by"] = data["by"];
    obj["from"] = data["from"] || "";

    result_list.push(JSON.stringify(obj));
  });

  let final_list = Array();
  result_list.map((data) => {
    final_list.push(JSON.parse(data));
  });

  return final_list;
}
