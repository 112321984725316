import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testimonies: [],
  reputations: [],
  news: [],
  settings: null,
  list: [],
  payment_id: "",
  delivery_id: "",
  delivery_address: "",
  notes: "",
  now: "",
  phone_number: "",
  loading: true, // Add loading state
};

const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setTestimonies: (prevState, action) => {
      return {
        ...prevState,
        testimonies: action.payload,
      };
    },
    setReputations: (prevState, action) => {
      return {
        ...prevState,
        reputations: action.payload,
      };
    },
    setNews: (prevState, action) => {
      return {
        ...prevState,
        news: action.payload,
      };
    },
    setSettings: (prevState, action) => {
        return {
          ...prevState,
          settings: action.payload,
        };
      },
      setLoading: (prevState, action) => ({
        ...prevState,
        loading: action.payload,
      }),
  },
});

export const { setTestimonies, setReputations, setNews, setSettings, setLoading } = mainSlice.actions;
export default mainSlice.reducer;
