import {
    getFirestore,
    collection,
    getDocs,
    getDoc,
    query,
    where,
    doc,
    addDoc,
    updateDoc,
    orderBy,
    deleteDoc,
    setDoc,
  } from "firebase/firestore";

  import { getFbDatabase, getFbStorage } from "../../firebaseConn";

  const db = getFbDatabase();

export async function getAllProducts() {
    const productList = Array();
  
    const productRef = collection(db, "products");
    const productSnapshot = await getDocs(productRef);
  
    productSnapshot.forEach((doc) => {
      const data = doc.data();
      data["_id"] = doc.id;
      productList.push(data);
    });
  
    let result_list = Array();
  
    productList.map((data) => {
      const obj = {};
      obj["_id"] = data["_id"];
      obj["title"] = data["title"];
      obj["description"] = data["description"];
      obj["services"] = data["services"];
      obj["image"] = data["image"] || "";
    
      result_list.push(JSON.stringify(obj));
  
      
    });
  
    let final_list = Array();
    result_list.map((data) => {
      final_list.push(JSON.parse(data));
    });
    
    return final_list;
  }

