import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    jobs: [],
    
  };

  const careerSlice = createSlice({
    name: "career",
    initialState,
    reducers: {
        setJobs: (prevState, action) => {
            
            return {
              ...prevState,
              jobs: action.payload,
        
            };
          },
        
    },

})

export const {setJobs} = careerSlice.actions;
export default careerSlice.reducer;