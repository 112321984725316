import { combineReducers } from "redux";

import contextSlice from "./context.slice";
import profileSlice from "./profile.slice";
import uinfoSlice from "./userInfo.slice";
import mainSlice from "./main.slice";
import serviceSlice from "./service.slice";
import productSlice from "./product.slice";
import aboutSlice from "./about.slice";
import firebaseSlice from "./firebase.slice";
import careerSlice from "./career.slice";

const reducers = combineReducers({
  userInfo: uinfoSlice,
  profile: profileSlice,
  context: contextSlice,
  main:mainSlice,
  service:serviceSlice,
  product:productSlice,
  about:aboutSlice,
  firebase: firebaseSlice,
  career: careerSlice,
});

export default reducers;
