import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { HashLink } from "react-router-hash-link";

import { toast } from "react-hot-toast";

// assets icons
import headIcon from "../assets/icons/headphone.png";
import quotesIcon from "../assets/icons/double_quotes.svg";
import news1Image from "../assets/images/linsta_services_news.jpeg";

// components
import Footer from "../components/Footer";
import Header from "../components/Header";

import { getFileURL } from "../utils/fb_helper";

import {
  getAllTestimonies,
  getAllReputations,
  getAllSettings,
} from "../utils/dataProvider/firebase/fb_main";

import {
  setTestimonies,
  setReputations,
  setSettings,
} from "../redux/slices/main.slice";
import { setServiceIcons } from "../redux/slices/service.slice";

import Loading from "../components/Loading";

class Mainpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewportType: this.getViewportType(),
      date: new Date(),
    };
  }

  getViewportType = () => {
    const width = window.innerWidth;

    if (width < 640) {
      return "sm";
    } else if (width >= 640 && width < 768) {
      return "md";
    } else if (width >= 768 && width < 1024) {
      return "lg";
    } else if (width >= 1024 && width < 1280) {
      return "xl";
    } else {
      return "2xl";
    }
  };

  handleResize = () => {
    this.setState({ viewportType: this.getViewportType() });
  };

  getServiceIcon = async (filepath, filename, default_icon) => {
    const iconURL = await getFileURL(filepath, filename, default_icon);
    return iconURL;
  };

  fetchValues = async () => {
    const { testimonies, serviceIcons, reputations } = this.props;

    if (testimonies.length === 0 || true) {
      const fetched_testimonies = await Promise.resolve(getAllTestimonies());
      this.props.setTestimonies(fetched_testimonies);
    }

    if (reputations.length === 0 || true) {
      const fetched_reputations = await Promise.resolve(getAllReputations());
      this.props.setReputations(fetched_reputations);
    }

   

    if (serviceIcons.length === 0) {
      const fetched_serviceIcons = await Promise.all(
        this.props.services.map(async (service, idx) => {
          const serviceIconName = `service_icon_${String(idx + 1)}.svg`;
          const serviceIcon = await this.getServiceIcon(
            "icons/services",
            serviceIconName,
            headIcon
          );
          return serviceIcon;
        })
      );
      this.props.setServiceIcons(fetched_serviceIcons);
    }
  };

  componentDidMount() {
    this.fetchValues();

    window.addEventListener("resize", this.handleResize);
    this.handleResize(); // Set initial state
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.viewportType !== prevState.viewportType) {
      console.log("Viewport type changed:", this.state.viewportType);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getServiceBgColor = (idx) => {
    let type = this.state.viewportType;

    if (type === "sm") {
      if (idx % 4 === 1 || idx % 4 === 2) {
        // console.log(type)
        return ["[#2D7599]", "[#ffffff]"];
      } else {
        return ["[#ffffff]", "[#2D7599]"];
      }
    } else {
      if (idx % 2 === 0) {
        return ["[#ffffff]", "[#2D7599]"];
      } else {
        return ["[#2D7599]", "[#ffffff]"];
      }
    }
  };

  render() {
    if (this.props.firebase.loading || this.props.settings == null)
      return (
        <>
          <Loading />
        </>
      );

    if (this.props.firebase.error) return <div>Error: Database Error</div>;

    return (
      <Fragment>
        <Header />
        <main>
          <section className="bg-text-image">
            <div className="main-px pl-6 pt-12 md:pl-[72px] md:pt-[200px]">
              <div className="flex flex-col gap-6 w-full md:w-[853px] h-auto md:h-[166px] text-sm">
                <h2 className="main-bg-text text-[#292E3D] font-[700] text-2xl md:text-[72px] leading-normal md:leading-[82.79px]">
                  {/* Empowering innovation, Ensuring Reliability */}

                  {this.props.settings == null
                    ? ""
                    : this.props.settings.main.heading}
                </h2>
              </div>
            </div>
          </section>

          <section className="global-px mt-10">
            <div className="flex flex-col items-center pt-5">
              <h2 className="text-3xl md:text-[54px] text-quartenary font-bold leading-[40px] md:leading-[62px] mb-5 text-center">
                Welcome to Linsta Power
              </h2>
              <p className="w-full md:w-[754px] font-[400] leading-6 md:leading-[30px] text-center px-4">
                {/* A leading provider of cutting-edge electrical solutions. With a rich history spanning 18 years, we have been at the forefront of innovation, delivering excellence in every project. Established in 2006, we have been crafting top-tier electrical panel boards tailored to meet industry standards. */}
                {this.props.settings == null
                  ? ""
                  : this.props.settings.main.welcome_description}
              </p>
            </div>
          </section>

          <section className="global-px py-8 md:py-20">
            <div className="flex flex-col items-center mb-8 md:mb-12">
              <h2 className="text-4xl text-quartenary font-bold text-center">
                Our Reputation
              </h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 px-4 md:px-8">
              {this.props.reputations.map((reputation, idx) => (
                <div
                  key={idx}
                  className="card bg-base-100 w-full h-[200px] max-w-[340px] max-h-[200px] border-solid rounded-[1.4px] border-2 border-[#E0E3EB] m-auto"
                >
                  <div className="card-body pt-4">
                    <div className="flex items-center">
                      <img
                        className="w-[32px]"
                        src={reputation.icon ? reputation.icon : headIcon}
                        alt=""
                      />
                      <h2 className="text-[20px] text-quartenary font-bold leading-[23.46px] px-3">
                        {reputation.title}
                      </h2>
                    </div>
                    <p className={`text-[#858EAD] ${reputation.contents && reputation.contents.length >=3 ? 'font-[400] text-[14px]':'font-[400] text-[16px]'} py-1`}>
                      {reputation.contents && reputation.contents.map((content,idc)=>(<div key={idc}>{content}</div>))}
                      
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section className="pt-4 md:pt-12 pb-1 md:pb-6 bg-[#F4F7FA]">
            <div className="global-px">
              <div className="flex flex-col items-center mb-4 md:mb-10">
                <h2 className="text-4xl text-quartenary font-bold text-center">
                  Services
                </h2>
              </div>
              <div className="px-4 md:px-8 pb-8">
                <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-3">
                  {this.props.services.map((service, idx) => {
                    const [smBgColor, smTextColor] =
                      this.getServiceBgColor(idx);
                    const serviceIcon = this.props.serviceIcons[idx];
                    return (
                      <div
                        key={idx}
                        className={`card w-full max-w-[271px] h-[180px] border-solid rounded-[1.4px] border-2 border-[#E0E3EB] m-auto bg-${smBgColor}`}
                      >
                        <HashLink
                          smooth
                          to={`/services#service${idx + 1}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="card-body p-4 md:p-6">
                            <div className="flex justify-center">
                              <img
                                className="w-10 bg-${smTextColor}"
                                src={service.icon ? service.icon :serviceIcon}
                                alt=""
                              />
                            </div>
                            <div className="w-10 mx-auto">
                              <div className="border-b-2 border-[#E0E3EB]"></div>
                            </div>
                            <h2
                              className={`text-center font-bold leading-[23.46px] text-${smTextColor}`}
                            >
                              {service.title}
                            </h2>
                          </div>
                        </HashLink>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <section className="bg-product-image">
            <div className="px-4 md:px-[165px] py-20 h-auto">
              <div className="flex flex-col gap-6 w-full md:w-[505px] h-[129px] text-sm text-left">
                <h2 className="text-2xl md:text-[36px] text-quartenary font-bold font-[700] leading-tight md:leading-[48px] text-left">
                  {this.props.settings == null
                    ? ""
                    : this.props.settings.products.heading}
                </h2>
                <p className="text-base md:text-[19px] text-quartenary font-[400] leading-[32px] text-left">
                  {this.props.settings == null
                    ? ""
                    : this.props.settings.products.main_description}
                </p>
              </div>

              <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-5 lg:gap-5 pt-12">
                {this.props.products.map((product, idx) => {
                  return (
                    <div
                      key={idx}
                      className="card hover:bg-[#2D7599] bg-[#EDF6FF] w-full max-w-[255px] h-[117px] border-solid rounded-lg border-1 border-[#E7E9ED] m-auto"
                    >
                      <HashLink
                        smooth
                        to={`/products#product${idx + 1}`}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="card-body p-2 md:p-4">
                          <h2 className="text-base md:text-[20px] hover:text-[#ffffff] text-quartenary font-[600] leading-[32px]">
                            {product.title}
                          </h2>
                          <p className="text-[#858EAD] font-[400] text-base"></p>
                        </div>
                      </HashLink>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <section className="pt-4 md:pt-12 pb-1 md:pb-6 bg-[#F4F7FA]">
            <div className="global-px">
              <div className="px-4 md:px-8 pb-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-3">
                  {this.props.testimonies.map((test, idx) => {
                    return (
                      <div
                        key={idx}
                        className="card bg-base-100 w-full max-w-[336px] border-solid rounded-[1.4px] border-2 border-[#f1f1f1] m-auto"
                      >
                        <div className="card-body p-4 md:p-6">
                          <img
                            className="w-10 pb-2"
                            src={quotesIcon}
                            alt=""
                          ></img>
                          <h2 className="text-base md:text-[24px] text-quartenary font-[500] leading-tight md:leading-[34.46px]">
                            {test.content}
                          </h2>
                          <span className="text-[#858EAD] font-[400] leading-[12px] text-xs mb-1">
                            {test.by}, {test.from}
                          </span>
                          <span className="text-[#858EAD] font-[400] leading-[12px] text-xs mb-1">
                            {test.detail}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <section className="global-px py-8 md:py-20">
            <div className="flex flex-col items-center mb-8 md:mb-20">
              <h2 className="text-quartenary font-[700] text-[28px] md:text-[38px] text-center">
                {this.props.settings == null
                  ? ""
                  : this.props.settings.updates.heading}
              </h2>
              <h2 className="text-quartenary font-[400] text-[14px] md:text-[16px] text-center">
                {this.props.settings == null
                  ? ""
                  : this.props.settings.updates.main_description}
              </h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 px-4 md:px-8">
              {this.props.news.map((update, idx) => {
                return (
                  <div key={idx} className="card rounded-[2px] bg-base-100">
                    <figure>
                      <img
                        src={update.image != "" ? update.image : news1Image}
                        alt=""
                        className="w-full h-auto"
                      />
                    </figure>
                    <div className="card-body h-[110px] px-4 md:px-0 text-left">
                      <p className="text-sm md:text-base">{update.type}</p>
                      <h2 className="card-title text-base md:text-lg">
                        {update.title}
                      </h2>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  testimonies: state.main.testimonies,
  services: state.service.services,
  products: state.product.products,
  serviceIcons: state.service.serviceIcons,
  reputations: state.main.reputations,
  news: state.main.news,
  settings: state.main.settings,
  firebase: state.firebase,
});

const mapDispatchToProps = {
  setTestimonies,
  setServiceIcons,
  setReputations,
  setSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Mainpage);
