import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    objectives: [],
    team:[],
    certificates:[],
    
  };

  const aboutSlice = createSlice({
    name: "about",
    initialState,
    reducers: {
        setObjectives: (prevState, action) => {
            return {
              ...prevState,
              objectives: action.payload,
        
            };
          },
          setTeam: (prevState, action) => {
            return {
              ...prevState,
              team: action.payload,
        
            };
          },
          setCertifications:(prevState, action) => {
            return {
              ...prevState,
              certificates: action.payload,
        
            };
          },
        
    },

})

export const {setObjectives,setTeam,setCertifications} = aboutSlice.actions;
export default aboutSlice.reducer;