import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  query,
  where,
  doc,
  addDoc,
  updateDoc,
  orderBy,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

import { getFbDatabase, getFbStorage } from "../../firebaseConn";

const db = getFbDatabase();

export async function getAllTestimonies() {
  const testimonyList = Array();

  const testimonyRef = collection(db, "testimonies");
  const testimonySnapshot = await getDocs(testimonyRef);

  testimonySnapshot.forEach((doc) => {
    const data = doc.data();
    data["_id"] = doc.id;
    testimonyList.push(data);
  });

  let result_list = Array();

  testimonyList.map((data) => {
    const obj = {};
    obj["_id"] = data["_id"];
    obj["content"] = data["content"];
    obj["detail"] = data["detail"];
    obj["by"] = data["by"];
    obj["from"] = data["from"] || "";

    result_list.push(JSON.stringify(obj));
  });

  let final_list = Array();
  result_list.map((data) => {
    final_list.push(JSON.parse(data));
  });

  return final_list;
}

export async function getAllReputations() {
  const reputationList = Array();

  const reputationRef = collection(db, "reputations");
  const reputationSnapshot = await getDocs(reputationRef);

  reputationSnapshot.forEach((doc) => {
    const data = doc.data();
    data["_id"] = doc.id;
    reputationList.push(data);
  });

  // let result_list = Array();

  // reputationList.map((data) => {
  //   const obj = {};
  //   obj["_id"] = data["_id"];
  //   obj["title"] = data["title"];
  //   obj["content"] = data["content"];

  //   result_list.push(JSON.stringify(obj));
  // });

  // let final_list = Array();
  // result_list.map((data) => {
  //   final_list.push(JSON.parse(data));
  // });

  return reputationList;
}

export async function getAllSettings() {
  // const docId = "awQGIq1W1sMJA1NByLpP";
  // const settingsDoc = doc(db, "page_settings", docId);
  // const docSnapshot = await getDoc(settingsDoc);
  // const output = null;
  // if (docSnapshot.exists()) {
  //   output = {
  //     id: docSnapshot.id,
  //     ...docSnapshot.data(),
  //   };
  // }

  const settingsList = Array();
  const settingsRef = collection(db, "page_settings");
  const settingsSnapshot = await getDocs(settingsRef);

  settingsSnapshot.forEach((doc) => {
    const data = doc.data();
    data["_id"] = doc.id;
    settingsList.push(data);
  });

  return settingsList;

}
