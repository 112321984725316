import "react-loading-skeleton/dist/skeleton.css";

/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { toast } from "react-hot-toast";
import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import useDocumentTitle from "../../utils/documentTitle";

import {
  checkDateExpiry,
  isValidEmail,
  validateToken,
} from "../../utils/helpers";
import {
  applyCandidateJob,
  getJobCandidates,
} from "../../utils/dataProvider/firebase/fb_career";
import DeleteApplicant from "../../components/DeleteApplicant";

function CareerDetails(props) {
  const contentRef = useRef(null);
  const [job, setJob] = useState(null);
  const [candidates, setCandidates] = useState(null);

  const navigate = useNavigate();

  const { jobId } = useParams();

  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfo,
  }));

  const [isLoading, setLoading] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);

  const [applicantId, setApplicantId] = useState(null);

  const mainstore = useSelector((state) => state.main);
  const careerstore = useSelector((state) => state.career);

  const [form, setForm] = useState({
    full_name: "",
    email: "",
    phone: "",
    subject: "Job Application",
    message: "",
    attachment: "",
    job_id: jobId,
  });

  const formChangeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setForm({ ...form, attachment: "" });
      return;
    }

    if (e.target.files[0].size > 2097152) {
      return toast.error("Files must not exceed 2 MB");
    }

    // I've kept this example simple by using the first image instead of multiple
    setForm({ ...form, attachment: e.target.files[0] });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      form.full_name === "" ||
      form.email === "" ||
      form.phone === "" ||
      form.subject === ""
    ) {
      return toast.error("Input required form");
    }

    if (!isValidEmail(form.email)) {
      return toast.error("Input valid email id");
    }

    if (form.phone.length < 10) {
      return toast.error("Input valid mobile number");
    }

    if (form.attachment == "") {
      return toast.error("Please attach resume or CV.");
    }

    setLoading(true);
    applyCandidateJob(form)
      .then((result) => {
        navigate(`/careers`);
        toast.success("Job application submitted.");
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (Number(props.userInfo.role) >= 2) {
      getJobCandidates()
        .then((result) => {
          const candidates = result.filter((c) => c.job_id == jobId);

          setCandidates(candidates);
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    const fetchjob = careerstore.jobs.filter((item) => item.id == jobId);

    setJob(fetchjob[0]);
    contentRef.current.innerHTML = fetchjob[0].job_description;
  }, []);

  const downloadFile = (url) => {
    // Open the link in a new tab
    window.open(url, "_blank");
  };

  const deleteApplicant = (id) => {
    setApplicantId(id);
    setDeleteModal(true);
  };

  const closeModelOpen = () => {
    setApplicantId(null);
    setDeleteModal(false);
  };

  useDocumentTitle("Careers");

  return (
    <>
      <DeleteApplicant
        isOpen={deleteModal}
        onClose={() => closeModelOpen()}
        applicantId={applicantId}
      />
      <Header />

      <main>
        <div className="bg-[#F4F7FA]">
          <section className="px-4 md:px-[72px] py-4 md:py-8 h-auto">
            <div className="flex flex-col gap-4 md:gap-6 text-center">
              <h2 className="text-[24px] md:text-[36px] text-quartenary font-bold leading-[32px] md:leading-[48px] text-center">
                {job == null ? "" : job.job_title}
              </h2>
            </div>
          </section>

          <section className="px-4 md:px-[72px] py-4 md:py-8">
            <div className="py-5">
              {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
              <div className="createdList" ref={contentRef} />
            </div>
          </section>

          <section className="px-4 md:px-[72px] py-4 md:py-8">
            {job &&
            checkDateExpiry(job.job_expiry_date >= 0) &&
            candidates == null ? (
              <form
                onSubmit={submitHandler}
                className="card bg-base-100 shadow-xl"
              >
                <div className="card-body">
                  <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 lg:gap-5 gap-3 px-4 md:px-8 lg:mb-4 mb-4">
                    <div className="">
                      <p>Full Name</p>
                      <input
                        type="text"
                        placeholder="i.e. John Doe"
                        name="full_name"
                        id="full_name"
                        value={form.full_name}
                        onChange={formChangeHandler}
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="">
                      <p>Email</p>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        value={form.email}
                        onChange={formChangeHandler}
                        placeholder="i.e. john@mail.com"
                        className="input input-bordered w-full "
                      />
                    </div>
                    <div className="">
                      <p>Phone Number</p>
                      <input
                        type="number"
                        name="phone"
                        id="phone"
                        value={form.phone}
                        onChange={formChangeHandler}
                        placeholder="i.e. +1-234-567-7890"
                        className="input input-bordered w-full "
                      />
                    </div>
                    <div className="">
                      <p>Subject</p>
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        value={form.subject}
                        onChange={formChangeHandler}
                        placeholder="i.e. I need a help"
                        className="input input-bordered w-full "
                      />
                    </div>
                    <div className="lg:col-span-2">
                      <p>Message</p>
                      <textarea
                        name="message"
                        id="message"
                        value={form.message}
                        onChange={formChangeHandler}
                        className="textarea textarea-bordered w-full"
                        placeholder="Type your message"
                      ></textarea>
                    </div>
                    <div className="lg:col-span-2">
                      <p>Attach file</p>
                      <input
                        type="file"
                        name="attachment"
                        id="attachment"
                        onChange={onSelectFile}
                        className="file-input file-input-bordered w-full"
                        accept=".pdf,.doc,.docx"
                      />
                    </div>
                  </div>

                  <div className="card-actions justify-end px-4 md:px-8">
                    <button
                      type="submit"
                      onClick={submitHandler}
                      className={`${
                        isLoading && "loading"
                      } btn btn-secondary text-[#ffffff]`}
                    >
                      Apply for this job
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              ""
            )}
            {job && checkDateExpiry(job.job_expiry_date) === -1 ? (
              <div className="card bg-base-100 w-full shadow-xl">
                <div className="card-body">
                  <p>This job requirement is presently unavailable.</p>
                </div>
              </div>
            ) : (
              ""
            )}

            {Number(props.userInfo.role) >= 2 && candidates != null
              ? candidates.map((job, idx) => {
                  return (
                    <div key={idx} className="card bg-base-100 mb-4  shadow-xl">
                      <div className="card-body">
                        <div className="grid grid-cols-4 md:grid-cols-4 gap-4">
                          <div className="flex items-center gap-3">
                            <div>
                              <div className="font-bold">{job.full_name}</div>
                              <div className="text-sm opacity-50">
                                {job.email}
                              </div>
                              <div className="text-sm opacity-50">
                                {job.phone}
                              </div>
                            </div>
                          </div>

                          <div className="flex items-left gap-3">
                            <div>
                              <div className="">Subject: {job.subject}</div>
                              <div className="text-sm opacity-50">
                                Applied date: {job.job_apply_date}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-left gap-3">
                            {job.message ? (
                              <span className="badge badge-ghost badge-sm">
                                {job.message}
                              </span>
                            ) : (
                              <div className="text-sm opacity-50">
                                No message from Candidate.
                              </div>
                            )}
                          </div>

                          <div className="flex justify-center items-center gap-3">
                            {job.attachment && job.attachment != null ? (
                              <>
                              <div className="flex flex-col items-center space-y-2">
                                <button
                                  onClick={() => downloadFile(job.attachment)}
                                  className="btn btn-warning btn-xs"
                                >
                                  View File
                                </button>
                                <button
                                  onClick={() => deleteApplicant(job._id)}
                                  className="btn btn-error btn-xs"
                                >
                                  Delete
                                </button>
                              </div>
                            </>
                            
                            
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CareerDetails);
