import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  query,
  where,
  doc,
  addDoc,
  updateDoc,
  orderBy,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

import { Timestamp } from "firebase/firestore";

import { getFbDatabase } from "../../firebaseConn";
import { getTimeStampDate, validateToken } from "../../helpers";

const db = getFbDatabase();

export async function createDocument(col_name, form) {
  const docRef = await addDoc(collection(db, col_name), form);
  return docRef;
}

export async function sendMessage({
  full_name,
  email,
  phone,
  subject,
  message,
}) {
  const bodyForm = {};
  bodyForm.full_name = full_name;
  bodyForm.email = email;
  bodyForm.phone = phone;
  bodyForm.msg_send_date = Timestamp.fromDate(new Date());
  bodyForm.subject = subject;
  bodyForm.message = message;
  bodyForm.mail_status = "added";

  const final_out = {};

  try {
    const docRef = createDocument("messages", bodyForm);
    final_out["msg"] = "Item added successfully.";
  } catch (error) {
    final_out["msg"] = error.message;
  }

  return final_out;
}

export const deleteGuestMessage = async (messageId, token) => {
  if(token.length > 0){
    const final_out = {}

    const docRef = doc(db, "messages", messageId);
    try {
  
      const docSnap = await deleteDoc(docRef);
      
        
        final_out['msg'] = "Successfully deleted message."
  
        return final_out
     
    } catch (error) {
      final_out['msg'] = "Failed to delete message."
      console.error("Error getting document:", error);
    }
  }
  
};


export async function getMessages() {
    const messageList = Array();
  
    const messagesRef = collection(db, "messages");
    const messagesSnapshot = await getDocs(messagesRef );
  
    messagesSnapshot.forEach((doc) => {
      const data = doc.data();
      data["_id"] = doc.id;
      data["msg_send_date"] = getTimeStampDate(data.msg_send_date);
      messageList.push(data);
    });
  
  
    return messageList;
  }
