


import {
    getFirestore,
    collection,
    getDocs,
    getDoc,
    query,
    where,
    doc,
    addDoc,
    updateDoc,
    orderBy,
    deleteDoc,
    setDoc,
  } from "firebase/firestore";

  import { getFbDatabase, getFbStorage } from "../../firebaseConn";

  const db = getFbDatabase();

export async function getAllObjectives() {
    const objectiveList = Array();
  
    const objectiveRef = collection(db, "company_objectives");
    const objectiveSnapshot = await getDocs(objectiveRef);
  
    objectiveSnapshot.forEach((doc) => {
      const data = doc.data();
      data["_id"] = doc.id;
      objectiveList.push(data);
    });
  
    let result_list = Array();
  
    objectiveList.map((data) => {
      const obj = {};
      obj["_id"] = data["_id"];
      obj["heading"] = data["heading"];
      if ("content" in data){
        obj["content"] = data["content"];
      }

      if ("points" in data){
        obj["points"] = data["points"] ;
      }
      
      
    
      result_list.push(JSON.stringify(obj));
  
      
    });
  
    let final_list = Array();
    result_list.map((data) => {
      final_list.push(JSON.parse(data));
    });
  
    return final_list;
  }

  export async function getAllCertifications() {
    const certificateList = Array();
  
    const certificateRef = collection(db, "certificates");
    const certificateSnapshot = await getDocs(certificateRef);
  
    certificateSnapshot.forEach((doc) => {
      const data = doc.data();
      data["_id"] = doc.id;
      certificateList.push(data);
    });
  
    
  
    return certificateList;
  }

  export async function getTeam() {
    const teamList = Array();
  
    const teamRef = collection(db, "team");
    const teamSnapshot = await getDocs(teamRef);
  
    teamSnapshot.forEach((doc) => {
      const data = doc.data();
      data["_id"] = doc.id;
      teamList.push(data);
    });
  
    let result_list = Array();
  
    teamList.map((data) => {
      const obj = {};
      obj["_id"] = data["_id"];
      obj["name"] = data["name"];
      obj["designation"] = data["designation"];
      obj["image"] = data["image"] || "";
      

      result_list.push(JSON.stringify(obj));
  
      
    });
  
    let final_list = Array();
    result_list.map((data) => {
      final_list.push(JSON.parse(data));
    });
  
    return final_list;
  }


