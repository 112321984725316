import "react-loading-skeleton/dist/skeleton.css";

/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from "react";

import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";


import product1 from "../../assets/images/products_dummy.jpeg";

import rightArrow from "../../assets/icons/arrow_downward_24px.svg";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import useDocumentTitle from "../../utils/documentTitle";



import Loading from "../../components/Loading";

function Services(props) {
  // const [services, setServices] = useState([]);

  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfo,
  }));

  const servicestore = useSelector((state) => state.service);
  const mainstore = useSelector((state) => state.main);
  const { success, loading, error } = useSelector((state) => state.firebase);




  useDocumentTitle("Services");

  if (loading || mainstore.settings == null)
    return (
      <>
      <Loading/>
      </>
    );
  if (error) return <div>Error: Error: Database Error</div>;

  return (
    <>
      <Header />

      <main>
        <section className="px-4 md:px-[72px] py-4 md:py-8 h-auto">
          <div className="flex flex-col gap-4 md:gap-6 text-left">
            <h2 className="text-[24px] md:text-[36px] text-secondary font-bold leading-[32px] md:leading-[48px] text-left">
            {mainstore.settings == null ? "" : mainstore.settings.services.heading}
            </h2>
            <p className="text-[16px] md:text-[22px] text-quartenary font-[400] leading-[24px] md:leading-[32px] text-left">
            {mainstore.settings == null ? "" : mainstore.settings.services.page_description}
            </p>
          </div>
        </section>

        <section>
          <div className="px-4 md:px-[72px] py-4 md:py-8">
            {servicestore.services.map((service, idx) => {
              return (
                <div
                  id={`service${idx + 1}`}
                  key={idx}
                  className="grid grid-cols-1 md:grid-cols-3 gap-4"
                >
                  <div className="md:col-span-2">
                    <div className="grid grid-cols-1 md:grid-cols-8 gap-4">
                      <div className="relative flex items-start justify-end md:justify-end md:col-span-2">
                        <div className="py-4 md:py-[50px] flex justify-start md:justify-end">
                          <div className="heading-text text-[64px] md:text-[128px] text-[#E6E7E9] font-[400] leading-[48px] text-right md:relative md:right-[-25px] absolute md:static left-0 md:left-auto">
                            {idx + 1 >= 10 ? <>{idx + 1}</> : <>0{idx + 1}</>}
                          </div>
                        </div>
                      </div>
                      <div className="md:col-span-6">
                        <div className="flex flex-col gap-4 md:gap-6 pr-4 md:pr-[100px] py-4 md:py-[75px] text-left">
                          <h2 className="text-[24px] md:text-[32px] text-[#161C2D] font-[700] leading-[32px] md:leading-[48px]">
                            {service.title}
                          </h2>
                          <div className="lg:hidden md:hidden">
                            <figure className="scroll-container rounded-md h-[150px]">
                              <img
                                className="w-full  object-cover  object-top"
                                src={
                                  service.image != "" ? service.image : product1
                                }
                                alt="Album"
                              />
                            </figure>
                          </div>
                          <p className="text-[16px] md:text-[18px]">
                            {service.description}
                          </p>
                          <div className="flex items-center">
                            <span className="text-[16px] md:text-[18px] text-[#2947A9] font-[400]">
                              Services{" "}
                            </span>
                            <img
                              className="h-[12px] md:h-[16px] pl-2 md:pl-3"
                              src={rightArrow}
                              alt="Right Arrow"
                            />
                          </div>
                          <ul className="list-disc text-[14px] md:text-[18px] text-[#1A1A1A] font-[400] leading-[24px] md:leading-[32px] pl-4">
                            {service["services"].map((serv, ids) => (
                              <li key={ids}>{serv}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:col-span-1 hidden md:block">
                    <figure>
                      <img
                        className="object-cover"
                        src={service.image != "" ? service.image : product1}
                        alt="Product"
                      />
                    </figure>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
  service: state.service,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
