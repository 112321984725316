import React from 'react';

import { Link } from 'react-router-dom';

import fbLogo from '../assets/icons/facebook.svg';
import igLogo from '../assets/icons/instagram.svg';
import twLogo from '../assets/icons/twitter.svg';
import logo from "../assets/linsta_logo.png";

const AuthFooter = () => {
  return (
    <>
      <footer className="bg-[#F8F8F8] text-[#4f5665]">
        <div className="global-px lg:px-16">
          <div className="py-5  md:py-10"></div>
          <div className="flex flex-col-reverse gap-12 md:flex-row">
            <div className="flex flex-col gap-4 md:max-w-[50%]">
              <Link to="/">
                <div className="font-extrabold flex flex-row gap-2">
                  <img src={logo} alt="logo" width="90px" />{" "}
                </div>
              </Link>
              <div className="">
              A leading provider of cutting-edge electrical solutions.
              </div>
              {/* <div className="flex flex-row gap-5">
                <a
                  href="https://www.facebook.com/profile.php?"
                  className="bg-secondary h-[35px] w-[35px] flex items-center justify-center rounded-full"
                  aria-label="Facebook"
                >
                  <img src={fbLogo} alt="" />
                </a>
                <a
                  href="https://www.instagram.com//"
                  className="bg-secondary h-[35px] w-[35px] flex items-center justify-center rounded-full"
                  aria-label="Instagram"
                >
                  <img src={igLogo} alt="" />
                </a>
                
              </div> */}
              <div className="copyright">(c) 2024 Linsta Services</div>
            </div>
            
          </div>
          <div className="py-5"></div>
        </div>
      </footer>
    </>
  );
};

export default AuthFooter;
