import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs ,getDoc,query, where ,doc ,addDoc , updateDoc, orderBy ,deleteDoc ,setDoc  } from 'firebase/firestore';
import { getStorage, ref as storageRef , uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { groupByWeeks,groupByMonths , findDateInGroups} from "./fb_helper";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyBbrU0JlzbK_8OYEfaiWsMvWDStyIkQh9k",
  authDomain: "linsta-services-website.firebaseapp.com",
  projectId: "linsta-services-website",
  storageBucket: "linsta-services-website.appspot.com",
  messagingSenderId: "787318813091",
  appId: "1:787318813091:web:20b5151f993df3a5c46a16",
  measurementId: "G-YY9Y3D4CQP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


const db = getFirestore(app);

const storage = getStorage(app);

const auth = getAuth(app);

export function getFbStorage(){
  return storage
}

export function getFbDatabase(){
  return db
}

export function getFbAuth(){
  return auth
}

