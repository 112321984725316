import "react-loading-skeleton/dist/skeleton.css";

/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { HashLink } from "react-router-hash-link";
import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import contact1 from "../../assets/images/linsta_services_contact_person.jpeg";
import emp1 from "../../assets/images/emp1.jpg";

import rightArrow from "../../assets/icons/arrow_downward_24px.svg";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import useDocumentTitle from "../../utils/documentTitle";

import {
  setCertifications,
  setObjectives,
  setTeam,
} from "../../redux/slices/about.slice";
import {
  getAllCertifications,
  getAllObjectives,
  getTeam,
} from "../../utils/dataProvider/firebase/fb_about";
import ImageDisplay from "../../components/ImageDisplay";

function About(props) {
  const location = useLocation();

  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfo,
  }));

  const [] = useState([]);

  const [serviceIndex, setServiceIndex] = useState(0);
  const [productIndex, setProductIndex] = useState(0);

  const [imageOpen, setImageOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [serviceCertificates, setServiceCertificates] = useState([]);
  const [productCertificates, setProductCertificates] = useState([]);

  const aboutstore = useSelector((state) => state.about);
  const mainstore = useSelector((state) => state.main);
  const dispatch = useDispatch();

  const getObjective = async () => {
    const objectives = await Promise.resolve(getAllObjectives());
    dispatch(setObjectives(objectives));
  };

  const getCertifications = async () => {
    const certifications = await Promise.resolve(getAllCertifications());
    dispatch(setCertifications(certifications));
  };

  // const getTeamMembers = async () => {
  //   const team = await Promise.resolve(getTeam());
  //   dispatch(setTeam(team));
  // };

  useEffect(() => {
    getObjective();

    getCertifications();

    // if (aboutstore.team.length == 0) {
    //   getTeamMembers();
    // }
  }, []);

  useEffect(() => {
    if (aboutstore.certificates) {
      const service_certificates = aboutstore.certificates.filter(
        (item) => item.type == "services"
      );
      const product_certificates = aboutstore.certificates.filter(
        (item) => item.type == "products"
      );

      setServiceCertificates(service_certificates);
      setProductCertificates(product_certificates);
    }
  }, [aboutstore.certificates]);

  const nextServiceImage = () => {
    const visibleImages = window.innerWidth >= 1024 ? 3 : 2;
    if (serviceIndex + visibleImages < serviceCertificates.length) {
      setServiceIndex(serviceIndex + 1);
    }
  };

  const prevServiceImage = () => {
    if (serviceIndex > 0) {
      setServiceIndex(serviceIndex - 1);
    }
  };

  const nextProductImage = () => {
    const visibleImages = window.innerWidth >= 1024 ? 3 : 2;
    if (productIndex + visibleImages < productCertificates.length) {
      setProductIndex(productIndex + 1);
    }
  };

  const prevProductImage = () => {
    if (productIndex > 0) {
      setProductIndex(productIndex - 1);
    }
  };

  const viewImage = (cert) => {
   
      setSelectedImage(cert)
      setImageOpen(true)
  };

  const closeImageOpen = () => {
    setImageOpen(false);
    setSelectedImage(null)
};



  useDocumentTitle("About");
  return (
    <>
      <Header />
      <ImageDisplay imageOpen={imageOpen} selectedImage={selectedImage} closeImageOpen={closeImageOpen} />
      <main>
        <div className="bg-[#F4F7FA]">
          <section className="px-4 lg:pl-[72px] py-8 h-auto">
            <div className="grid grid-cols-1 lg:grid-cols-5">
              {/* Image Section */}
              <div className="lg:col-span-4 hidden lg:block">
                <figure>
                  <img
                    className="object-cover w-[903px] h-[669px]"
                    src={contact1}
                    alt="Album"
                  />
                </figure>
              </div>

              {/* Text Section */}
              <div className="lg:col-span-1 bg-[#2947A9] lg:bg-[#2947A9] lg:w-[488px] lg:h-[523px] lg:relative lg:left-[-355px] lg:top-[75px]">
                <div className="text-[#ffffff] lg:px-[50px] lg:py-[50px] text-left px-4 py-6">
                  <h2 className="text-[24px] lg:text-[36px] font-[700] lg:leading-[48px] leading-[30px]">
                    About us
                  </h2>
                  <p className="heading-text py-3 text-[16px] lg:text-[20px] font-[200] lg:leading-[26px] leading-[20px]">
                    Welcome to our state-of-the-art panel board manufacturing
                    unit and factory. We pride ourselves on precision
                    engineering and cutting-edge technology to produce
                    high-quality panel boards for diverse applications. Explore
                    our facilitys commitment to innovation, quality control, and
                    sustainable practices.
                  </p>
                  <div className="justify-start">
                    <button className="btn w-full lg:w-[270px] bg-[#ffffff] text-[#2947A9]">
                      Meet our Team
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="px-3 lg:px-[72px] py-8 h-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-5">
              {aboutstore.objectives.map((objective, idx) => {
                return (
                  <div key={idx} className="flex items-left">
                    <button className="w-[43px] h-[43px] btn btn-circle bg-[#d0cdfb] border-none mr-auto">
                      <span className="text-[#473BF0] text-[17px] font-[400]">
                        {idx + 1}
                      </span>
                    </button>
                    <div className="px-5 text-left">
                      <h2 className="text-[21px] font-[700] leading-[48px]">
                        {objective.heading}
                      </h2>
                      {"content" in objective ? (
                        <p className="heading-text py-5 text-[17px] font-[200] leading-[26px]">
                          {objective.content}
                        </p>
                      ) : (
                        ""
                      )}

                      {"points" in objective ? (
                        <ol className="list-decimal">
                          {objective.points.map((p, idp) => {
                            return (
                              <li
                                key={idp}
                                className="heading-text  text-[17px] font-[200] leading-[26px]"
                              >
                                {p}
                              </li>
                            );
                          })}
                        </ol>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </section>

          {false && (
            <section className="global-px mt-10">
              <div className="flex flex-col items-center pt-5 lg:mb-6 mb-4">
                <h2 className="text-3xl md:text-[54px] text-quartenary font-bold leading-[40px] md:leading-[62px] mb-5 text-center">
                  Meet our team
                </h2>
                <p className="w-full md:w-[754px] font-[400] leading-6 md:leading-[30px] text-center px-4">
                  With lots of unique blocks, you can easily build a page
                  without coding. Build your next consultancy website within
                  few.
                </p>
              </div>

              <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 lg:gap-10 gap-5 px-4 md:px-8 lg:mb-4 mb-4">
                {aboutstore.team.slice(0, 3).map((t, idx) => {
                  return (
                    <div key={idx} className="card rounded-[2px]">
                      <figure>
                        <img
                          src={t.image != "" ? t.image : emp1}
                          alt=""
                          className="w-full h-auto"
                        />
                      </figure>
                      <div className="card-body px-4 md:px-0 text-left">
                        <h2 className="card-title text-base md:text-lg">
                          {t.name}
                        </h2>
                        <p className="text-sm md:text-base">{t.designation}</p>
                      </div>
                    </div>
                  );
                })}

                <div className="card rounded-[2px]">
                  <div className="card-body px-4 md:px-0 text-center flex flex-col items-center justify-center">
                    <h2 className="card-title text-base md:text-lg text-[18px] md:text-[24px] font-[600] mb-4">
                      Interested to join our team?
                    </h2>
                    <HashLink
                      smooth
                      to="/contact#contactForm"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="flex items-center">
                        <span className="text-[16px] md:text-[18px] text-[#2947A9] font-[400]">
                          Apply now
                        </span>
                        <img
                          className="h-[12px] md:h-[16px] pl-2 md:pl-3"
                          src={rightArrow}
                          alt="Right Arrow"
                        />
                      </div>
                    </HashLink>
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className="bg-[#ffffff] px-3 lg:px-[72px] py-8 h-auto">
            <div className="flex flex-col gap-1 md:gap-2 text-left pb-3">
              <h2 className="text-[24px] md:text-[36px] text-quartenary font-bold leading-[32px] md:leading-[48px] text-left">
                Our Affiliations
              </h2>
              
            </div>

            
            {productCertificates && productCertificates.length == 0 ? (
              ""
            ) : (
              <>
                <div className="flex flex-col gap-1 md:gap-2 text-left pb-3">
                  <p className="text-[16px] md:text-[22px] text-quartenary font-[400] text-left">
                    Product affiliations
                  </p>
                </div>

                <div className="relative w-full overflow-hidden my-2">
                  <div
                    className="flex gap-1 md:gap-3 transition-transform duration-500 "
                    style={{
                      transform: `translateX(-${
                        productIndex *
                        (100 / (window.innerWidth >= 1024 ? 3 : 2))
                      }%)`,
                    }}
                  >
                    {productCertificates.map((sc, idx) => (
                      <img
                        key={idx}
                        src={sc.images[0]}
                        onClick={()=>viewImage(sc)}
                        alt={`carousel-${idx}`}
                        className="w-[calc(50%-4px)] md:w-[calc(33.333%-8px)] flex-shrink-0 object-cover"
                      />
                    ))}
                  </div>
                  <button
                    className={`absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-700 text-white p-2 ${
                      serviceIndex === 0 ? "opacity-50" : ""
                    }`}
                    onClick={prevProductImage}
                    disabled={productIndex === 0}
                  >
                    {"<"}
                  </button>
                  <button
                    className={`absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-700 text-white p-2 ${
                      productIndex + (window.innerWidth >= 1024 ? 3 : 2) >=
                      productCertificates.length
                        ? "opacity-50"
                        : ""
                    }`}
                    onClick={nextProductImage}
                    disabled={
                      productIndex + (window.innerWidth >= 1024 ? 3 : 2) >=
                      productCertificates.length
                    }
                  >
                    {">"}
                  </button>
                </div>
              </>
            )}

            {serviceCertificates && serviceCertificates.length == 0 ? (
              ""
            ) : (
              <>
                <div className="flex flex-col gap-1 md:gap-2 text-left py-3">
                  <p className="text-[16px] md:text-[22px] text-quartenary font-[400] text-left">
                    Service affiliations
                  </p>
                </div>

                <div className="relative w-full overflow-hidden my-2">
                  <div
                    className="flex gap-1 md:gap-3 transition-transform duration-500 "
                    style={{
                      transform: `translateX(-${
                        serviceIndex *
                        (100 / (window.innerWidth >= 1024 ? 3 : 2))
                      }%)`,
                    }}
                  >
                    {serviceCertificates.map((sc, idx) => (
                      <img
                        key={idx}
                        src={sc.images[0]}
                        alt={`carousel-${idx}`}
                        className="w-[calc(50%-4px)] md:w-[calc(33.333%-8px)] flex-shrink-0 object-cover"
                      />
                    ))}
                  </div>
                  <button
                    className={`absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-700 text-white p-2 ${
                      serviceIndex === 0 ? "opacity-50" : ""
                    }`}
                    onClick={prevServiceImage}
                    disabled={serviceIndex === 0}
                  >
                    {"<"}
                  </button>
                  <button
                    className={`absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-700 text-white p-2 ${
                      serviceIndex + (window.innerWidth >= 1024 ? 3 : 2) >=
                      serviceCertificates.length
                        ? "opacity-50"
                        : ""
                    }`}
                    onClick={nextServiceImage}
                    disabled={
                      serviceIndex + (window.innerWidth >= 1024 ? 3 : 2) >=
                      serviceCertificates.length
                    }
                  >
                    {">"}
                  </button>
                </div>
              </>
            )}
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(About);
