// App.jsx or FirebaseListener.jsx
import React, { useEffect, createContext } from "react";
import { useDispatch } from "react-redux";
import { doc, collection, onSnapshot, getDoc } from "firebase/firestore";
import { getFbDatabase } from "./utils/firebaseConn";
import { setProducts } from "./redux/slices/product.slice";
import { setServices } from "./redux/slices/service.slice";
import { setNews, setSettings, setLoading } from "./redux/slices/main.slice";
import { setJobs } from "./redux/slices/career.slice";

import {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFailure,
} from "./redux/slices/firebase.slice";
import { getTimeStampDate } from "./utils/helpers";

const FirebaseContext = createContext(null);
export { FirebaseContext };

const FirebaseListener = ({ children }) => {
  const dispatch = useDispatch();
  const db = getFbDatabase(); // get the database reference

  async function getFirebaseData() {
    dispatch(fetchDataStart());
    try {
      const docId = "awQGIq1W1sMJA1NByLpP";
      const productCollection = collection(db, "products");
      const serviceCollection = collection(db, "services");
      const jobsCollection = collection(db, "jobs");
      const newsCollection = collection(db, "news");
      const settingsDoc = doc(db, "page_settings", docId);

      const unsubscribeProducts = onSnapshot(productCollection, (snapshot) => {
        const fetched_products = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(setProducts(fetched_products));
      });

      const unsubscribeServices = onSnapshot(serviceCollection, (snapshot) => {
        const fetched_services = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(setServices(fetched_services));
      });

      const unsubscribeNews = onSnapshot(newsCollection, (snapshot) => {
        const fetched_news = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(setNews(fetched_news));
      });

      const unsubscribeJobs = onSnapshot(jobsCollection, (snapshot) => {
        const fetched_jobs = snapshot.docs.map((doc) => {
          const fetched_data = doc.data()
          return {
            id: doc.id,
            job_id : fetched_data.job_id,
            job_type : fetched_data.job_type,
            job_title :  fetched_data.job_title,
            job_summary : fetched_data.job_summary,
            job_added_date :  getTimeStampDate(fetched_data.job_added_date),
            job_expiry_date  :  getTimeStampDate(fetched_data.job_expiry_date),
            job_description : fetched_data.job_description,
          }
        }

          
      );
        dispatch(setJobs(fetched_jobs));
      });

      const unsubscribeSettings = onSnapshot(settingsDoc, (docSnapshot) => {
        if (docSnapshot.exists()) {
          dispatch(
            setSettings({
              id: docSnapshot.id,
              ...docSnapshot.data(),
            })
          );
        } else {
          dispatch(setSettings(null)); // Handle the case where the document does not exist
        }
      });

      dispatch(fetchDataSuccess());
      // Cleanup on unmount
      return () => {
        unsubscribeProducts();
        unsubscribeServices();
        unsubscribeNews();
        unsubscribeJobs();
        unsubscribeSettings();
      };
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  }
  // let firebase = 123
  let firebase = {
    initialFetch: getFirebaseData,
  };

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseListener;
