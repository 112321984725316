import React from "react";

import logo from "../assets/linsta_logo.png";

function Loading() {
  return (
    <main className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="flex items-center justify-center min-h-screen">
            <div className="font-extrabold" >
                <img src={logo} alt="logo" width="160px" />
                <progress className="progress progress-secondary" width="160px"></progress>
            </div>
      </div>
      </main>
  );
}

export default Loading;
