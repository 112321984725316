import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    services: [],
    serviceIcons: [],
    
  };

  const serviceSlice = createSlice({
    name: "services",
    initialState,
    reducers: {
        setServices: (prevState, action) => {
            return {
              ...prevState,
              services: action.payload,
        
            };
          },
        setServiceIcons: (prevState, action) => {
            return {
              ...prevState,
              serviceIcons: action.payload,
        
            };
          },
    },

})

export const {setServices,setServiceIcons} = serviceSlice.actions;
export default serviceSlice.reducer;